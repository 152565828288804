/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/prop-types */
import i18n from "../../i18n";
import { useState, useEffect, useRef } from "react";
import { Button, Form, Input, Modal } from "antd";
import "../styles/Cart.css";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";

const CartShop = ({ cart, setCart, handleChange }) => {
  const { t } = useTranslation();
  const mainContentRef = useRef(null);
  const [sub_amount, setsub_amount] = useState(0);
  const [note, setNote] = useState("");
  const [deliveryFee] = useState(3000);
  const [deliveryKurdstanFee] = useState(5000);
  const [deliveryFeeIraq] = useState(8000);

  const [user, setUser] = useState(null);
  const [successModalVisible, setSuccessModalVisible] = useState(false);

  const getTranslatedName = (nameInput) => {
    let nameTranslations;

    // Check if the input is a JSON string and parse it
    if (typeof nameInput === "string") {
      try {
        nameTranslations = JSON.parse(nameInput);
      } catch (error) {
        console.error("Failed to parse name JSON:", error);
        return "Unnamed Product"; // Fallback if JSON parsing fails
      }
    } else if (typeof nameInput === "object" && nameInput !== null) {
      nameTranslations = nameInput; // Directly use the object if it's valid
    } else {
      return "Unnamed Product"; // Fallback if input is neither a string nor an object
    }

    // Get the current language
    const currentLanguage = i18n.language || "ku";

    // Return the translated name or fallback to 'ku' or a default message
    return (
      nameTranslations[currentLanguage] ||
      nameTranslations["ku"] ||
      "Unnamed Product"
    );
  };

  useEffect(() => {
    let ans = 0;
    let storeIds = new Set();
    cart.forEach((item) => {
      ans += item.quantity * item.discounted_price;
      storeIds.add(item.store_id);
    });

    const extraDeliveryFee = (storeIds.size - 1) * 3000;
    const extraDeliveryKurdstanFee = (storeIds.size - 1) * 5000;
    const extraDeliveryIraqFee = (storeIds.size - 1) * 8000;

    setsub_amount(ans);

    setTotalAmount(deliveryFee + ans + extraDeliveryFee);
    setTotalAmountKurdistan(
      deliveryKurdstanFee + ans + extraDeliveryKurdstanFee
    );
    setTotalAmountIraq(deliveryFeeIraq + ans + extraDeliveryIraqFee);
  }, [cart]);

  const [total_amount, setTotalAmount] = useState(0);
  const [total_amountKurdtsan, setTotalAmountKurdistan] = useState(0);
  const [total_amountIraq, setTotalAmountIraq] = useState(0);

  useEffect(() => {
    const userData = Cookies.get("user");
    if (userData) {
      setUser(JSON.parse(userData));
    }
  }, []);

  const handleRemove = (id) => {
    const updatedCart = cart.filter((item) => item.id !== id);
    setCart(updatedCart);
  };

  const { TextArea } = Input;

  const confirm = async () => {
    const cook = await Cookies.get("login");
    const orderJson = {
      total_delivery: deliveryFee,
      sub_amount: sub_amount,
      total_amount: total_amount,
      note: note,
    };

    if (user?.name !== null && user?.name !== undefined) {
      orderJson.username = user.name;
    }

    if (user?.number !== null && user?.number !== undefined) {
      orderJson.phone_number = user.number;
    }

    if (user?.email !== null && user?.email !== undefined) {
      orderJson.email = user.email;
    }

    if (user?.address !== null && user?.address !== undefined) {
      orderJson.address = user.address;
    }

    if (user?.id !== null && user?.id !== undefined) {
      orderJson.user_id = user.id.toString();
    }

    orderJson.products = JSON.stringify(
      cart.map((item) => ({
        product_id: item.id,
        quantity: item.quantity,
      }))
    );

    // eslint-disable-next-line eqeqeq
    if (cook == "true") {
      // eslint-disable-next-line no-unused-vars

      const URL = "https://bardawamplus.com/api/orders";

      console.log("Order JSON:", JSON.stringify(orderJson));
      await fetch(URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(orderJson),
      })
        .then((response) => response.json())
        .then((r) => {
          console.log("res:", r);

          console.log("Order Details:", orderJson);
          // message.success("Your order has been submitted successfully");
          setSuccessModalVisible(true);
        })
        .catch((error) => {
          console.error("Error submitting order:", error);
          // message.error("Failed to submit your order");
        });
    } else {
      window.location.href =
        "/Register?orderJson=" + encodeURIComponent(JSON.stringify(orderJson));
      // window.location.href = "/user?" + JSON.stringify(orderJson);
    }
  };

  // const layout = {};

  const onFinish = (values) => {
    confirm(values);
  };
  useEffect(() => {
    if (successModalVisible) {
      // Disable interactions on the rest of the page
      if (mainContentRef.current) {
        mainContentRef.current.setAttribute("inert", "");
      }
    } else {
      // Re-enable interactions when the modal is closed
      if (mainContentRef.current) {
        mainContentRef.current.removeAttribute("inert");
      }
    }
  }, [successModalVisible]);
  // console.log("Image URLs:", imageUrls); // This logs the URLs to the console for debugging purposes
  if (cart.length > 0) {
    return (
      <div style={{ direction: "rtl" }}>
        <article>
          <div
            ref={mainContentRef}
            tabIndex="-1" // Make the div focusable for accessibility
            aria-labelledby="cart-content"
          >
            {cart.map((item) => (
              <div className="cart_box" key={item.id}>
                <div className="cart_img">
                  <img
                    src={
                      item.image_url.startsWith("http")
                        ? item.image_url
                        : `https://bardawamplus.com/api/storage/${item.image_url}`
                    }
                  />
                </div>
                <div>
                  <p style={{ fontWeight: "bold" }}>
                    {getTranslatedName(item.name)}
                  </p>
                </div>

                <div>
                  <div>
                    <Button
                      style={{ fontWeight: "bold" }}
                      onClick={() => handleChange(item, +1)}
                    >
                      +
                    </Button>
                    <Button style={{ fontWeight: "bold" }}>
                      {item.quantity}
                    </Button>
                    <Button
                      onClick={() => handleChange(item, -1)}
                      style={{ fontWeight: "bold" }}
                    >
                      -
                    </Button>
                  </div>
                </div>
                <div>
                  <span style={{ fontWeight: "bold" }}>
                    IQD {item.discounted_price}
                  </span>
                </div>
                <div>
                  <Button
                    style={{
                      background: "red",
                      color: "white",
                      fontWeight: "bold",
                    }}
                    onClick={() => handleRemove(item.id)}
                  >
                    {t("remove")}
                  </Button>
                </div>
              </div>
            ))}
          </div>
          <div className="total">
            <span>{t("Cart")}</span>
            <span>IQD {sub_amount}</span>
          </div>

          <div className="total">
            <span>{t("Cart in Erbil")}</span>
            <span>IQD {total_amount}</span>
          </div>
          <div className="total">
            <span>{t("Cart in kurdtsan")}</span>
            <span>IQD {total_amountKurdtsan}</span>
          </div>
          <div className="total">
            <span>{t("Cart out kurdtsan")}</span>
            <span>IQD {total_amountIraq}</span>
          </div>
          <div>
            <Form
              // {...layout}
              onFinish={onFinish}
              initialValues={{ note: "" }}
              className="cart-form" // Apply CSS class to the form
            >
              <Form.Item
                name="note"
                label={t("Note")}
                rules={[{ required: true, message: t("Note out said") }]}
                className="cart-form-label"
              >
                <Input.TextArea
                  rows={3}
                  onChange={(e) => setNote(e.target.value)}
                  placeholder={t("Note in said")}
                  className="cart-form-input" // Apply CSS class to the input field
                />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="cart-form-submit-button"
                >
                  {t("order")}
                </Button>
              </Form.Item>
            </Form>
          </div>

          <Modal
            open={successModalVisible} // Change 'visible' to 'open'
            onCancel={() => setSuccessModalVisible(false)}
            footer={[
              <Button
                key="ok"
                type="primary"
                onClick={() => {
                  window.location.reload();
                  window.location.href = "/profile";
                  setSuccessModalVisible(false);
                }}
              >
                OK
              </Button>,
            ]}
          >
            <p>{t("Your order has been submitted successfully!")}</p>
          </Modal>
        </article>
      </div>
    );
  } else {
    return (
      <div>
        <h1 className="empty-cart-message">{t("CartShop is empty")}</h1>
      </div>
    );
  }
};

export default CartShop;

/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "../styles/ListVendor.css"; // Ensure this import is included
import { Card } from "antd"; // Ant Design imports
import { Link } from "react-router-dom";
import {
  PhoneOutlined,
  EnvironmentOutlined,
  HomeOutlined,
} from "@ant-design/icons"; // Import Ant Design icons

const LinksVendor = () => {
  const [category, setCategory] = useState([]);
  const { i18n } = useTranslation();

  const getApiProducts = async () => {
    try {
      const res = await fetch(`https://bardawamplus.com/api/stores`);
      if (!res.ok) {
        throw new Error(`HTTP error! Status: ${res.status}`);
      }
      const data = await res.json();
      setCategory(
        data.data.map((item) => {
          const nameTranslations = JSON.parse(item.name);
          const currentLanguage = i18n.language || "ku";
          const translatedName = nameTranslations[currentLanguage];

          const AddressTranslation = JSON.parse(item.address);
          const AddressLanguage = i18n.language || "ku";
          const translatedAddress = AddressTranslation[AddressLanguage];

          const imageUrl = item.logo
            ? `https://api.bardawamplus.com/storage/${item.logo}`
            : null;

          return {
            key: item.id,
            id: item.id,
            name: translatedName,
            image_url: imageUrl,
            address: translatedAddress,
            number: item.number || "",
          };
        })
      );
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getApiProducts();
  }, [i18n.language]);

  return (
    <div className="list-container">
      {/* Add Back to Home link with icon, aligned to the right */}
      <div className="back-to-home">
        <Link to="/">
          <HomeOutlined style={{ marginRight: "4px", color: "#FF5733" }} />{" "}
          {/* Change icon color */}
          <span style={{ color: "#FF5733" }}>Back to Home</span>{" "}
          {/* Change text color */}
        </Link>
      </div>

      <div className="category-list">
        {category.map((pp) => (
          <div key={pp.id} className="category-item">
            <Card className="Card-list">
              <div className="card-content">
                <div className="card-text">
                  <span>{pp.name}</span>
                  <br />
                  {pp.address && (
                    <span>
                      <EnvironmentOutlined style={{ marginRight: "4px" }} />
                      {pp.address}
                    </span>
                  )}
                  <br />
                  {pp.number && (
                    <span>
                      <PhoneOutlined style={{ marginRight: "4px" }} />
                      {pp.number}
                    </span>
                  )}
                </div>
                <Link
                  to={{
                    pathname: `/Category/${pp.id}`, // Navigate to the category page
                    state: { PP: pp }, // Pass pp data as state
                  }}
                >
                  <img
                    style={{
                      height: "120px", // Set height directly
                      width: "120px", // Set width directly
                      objectFit: "contain", // Ensure the image is contained within the set dimensions
                    }}
                    src={pp.image_url}
                    alt={pp.name}
                  />
                </Link>
              </div>
            </Card>
          </div>
        ))}
      </div>
    </div>
  );
};

export default LinksVendor;

import { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import "./App.css";

import Navbar from "./FrontEnd/UpdateNavBar/Navbar.js";
import Gift from "./FrontEnd/page/Gift.js";

import i18n from "./i18n";
import Footer from "./FrontEnd/Components/Footer.js";
import LinsVendor from "./FrontEnd/Category_vendor/ListVendor.js";
import AboutUs from "./FrontEnd/page/AboutUs.js";
import Profile from "./FrontEnd/page/Profile.js";
import HomeShop from "./FrontEnd/Navbarshoping/HomeShoping.js";
import CartShop from "./FrontEnd/Navbarshoping/CartSphop.js";
import { message } from "antd";
import { useTranslation } from "react-i18next";
import Login from "./FrontEnd/page/Login.js";
import Register from "./FrontEnd/page/Register.js";
import Network from "./FrontEnd/page/Network.js";
import NotFound from "./FrontEnd/page/NotFound.js";
import Category from "./FrontEnd/Category_vendor/Category.js";
function App() {
  useEffect(() => {
    i18n.changeLanguage("ku");
  }, []);
  const { t } = useTranslation();
  // eslint-disable-next-line no-unused-vars
  const [show, setShow] = useState(true);
  const [cart, setCart] = useState([]);
  // Define handleChange function

  const handleClick = (item) => {
    // Assuming 'cart' is managed by the parent component
    const existingItem = cart.find((cartItem) => cartItem.id === item.id);
    if (existingItem) {
      // If the item already exists in the cart, update its quantity
      const updatedCart = cart.map((cartItem) =>
        cartItem.id === item.id
          ? { ...cartItem, quantity: cartItem.quantity + 1 } // Increase quantity
          : cartItem
      );
      setCart(updatedCart);
      message.success(t("cart"));
    } else {
      // If the item does not exist in the cart, add it with quantity 1
      setCart([...cart, { ...item, quantity: 1 }]);
      message.success(t("cart"));
    }
  };

  //todo handleChange
  const handleChange = (item, d) => {
    let ind = -1;
    cart.forEach((data, index) => {
      if (data.id === item.id) ind = index;
    });
    const tempArr = cart;
    tempArr[ind].quantity += d;

    if (tempArr[ind].quantity === 0) tempArr[ind].quantity = 1;
    setCart([...tempArr]);
  };

  return (
    <div className="ALL">
      <Navbar size={cart.length} setShow={setShow} />
      <Routes>
        <Route
          path="/"
          element={<HomeShop handleClick={handleClick} setShow={setShow} />}
        />
        <Route
          path="Cart"
          element={
            <CartShop
              cart={cart}
              setCart={setCart}
              handleChange={handleChange}
            />
          }
        />

        <Route path="LinsVendor" element={<LinsVendor />} />
        <Route path="Register" element={<Register />} />
        <Route path="Login" element={<Login />} />
        <Route path="Profile" element={<Profile />} />
        <Route path="/Profile/Network/:id" element={<Network />} />
        <Route path="Gift" element={<Gift />} />
        <Route path="AboutUs" element={<AboutUs />} />
        <Route
          path="/category/:id"
          element={<Category handleClick={handleClick} />}
        />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
    </div>
  );
}
export default App;

/* eslint-disable jsx-a11y/img-redundant-alt */
import {
  EffectCoverflow,
  Pagination,
  Navigation,
  Autoplay,
} from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { Typography, Modal, Button } from "antd";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/scrollbar";
import "./Slider.css";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Shopping from "../Navbarshoping/image/shopping-cart.png";
import BackImage from "./images/next-button.png";
import ForwardImage from "./images/previous.png";
const PageSlider = ({ handleClick }) => {
  const { t, i18n } = useTranslation();
  const [dataSource, setdataSource] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [swiper, setSwiper] = useState(null);
  //todo --------------Api----------------------
  useEffect(() => {
    fetch(`https://bardawamplus.com/api/products/pinned`)
      .then((res) => res.json())
      .then((response) => {
        const parsedData = response.data.map((item) => {
          return item;
        });

        setdataSource(parsedData);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  const openModal = (item) => {
    setSelectedItem(item);
    setModalVisible(true);
  };

  const closeModal = () => {
    setSelectedItem(null);
    setModalVisible(false);
  };
  // Initialize Swiper with necessary modules and settings
  useEffect(() => {
    if (swiper) {
      swiper.update(); // Update Swiper when dataSource changes
    }
  }, [dataSource, swiper]);

  // Autoplay functionality
  useEffect(() => {
    if (swiper) {
      const timer = setInterval(() => {
        swiper.slideNext();
      }, 3000);

      // Clear the interval when the component unmounts or swiper changes
      return () => clearInterval(timer);
    }
  }, [swiper]);
  return (
    <div className="container">
      <h1 className="heading">{t("VIP")}</h1>
      <Swiper
        autoplay={{ delay: 3000, disableOnInteraction: false }}
        onSwiper={setSwiper}
        speed={800}
        spaceBetween={10}
        effect="coverflow"
        // grabCursor="true"
        // centeredSlides="true"
        grabCursor={true}
        centeredSlides={true}
        loop={true}
        slidesPerView={"auto"}
        coverflowEffect={{
          rotate: 50,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: true,
        }}
        navigation={{
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
          clickable: true,
        }}
        modules={[Navigation, Pagination, EffectCoverflow, Autoplay]}
        className="swiper_container"
      >
        {dataSource.map((item, index) =>
          item.pinned === 1 ? (
            <SwiperSlide key={index}>
              <div
                className="swiper-image-container"
                onClick={() => openModal(item)}
              >
                <div className="text-overlay">
                  <p>{JSON.parse(item.name)[i18n.language || "ku"]}</p>
                </div>
                <img
                  src={`https://bardawamplus.com/api/storage/${item.image_url}`}
                  alt={`Slide ${index}`}
                />
                <div className="bottom-overlay">
                  <p className="price">
                    {t("product")} : ${item.discounted_price}
                    <Typography.Text delete type="danger">
                      <span> </span>
                      {parseFloat(item.price).toFixed(0)}
                    </Typography.Text>
                  </p>
                  <p className="price">
                    {t("point")} : {item.points}
                  </p>
                </div>
              </div>
            </SwiperSlide>
          ) : null
        )}

        <div className="slider-controler">
          <div className="swiper-button-prev slider-arrow">
            {/* <ion-icon name="arrow-back-outline"></ion-icon> */}
            <img src={ForwardImage} width={35} height={35} alt="image" />
          </div>
          <div className="swiper-button-next slider-arrow">
            {/* <ion-icon name="arrow-forward-outline"></ion-icon> */}
            <img src={BackImage} width={35} height={35} alt="image" />
          </div>
          <div className="swiper-pagination"></div>
        </div>
      </Swiper>

      {/* Modal */}
      <Modal
        className="modal"
        title={
          selectedItem
            ? JSON.parse(selectedItem.name)[i18n.language || "ku"]
            : ""
        }
        style={{
          direction: "rtl",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
        open={modalVisible}
        onCancel={closeModal}
        footer={null}
      >
        {selectedItem && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img
              src={`https://bardawamplus.com/api/storage/${selectedItem.image_url}`}
              alt="Modal"
              style={{ width: "50%" }}
            />
            <p className="price">
              {t("product")} : ${selectedItem.discounted_price}
              <Typography.Text delete type="danger">
                <span> </span>
                {parseFloat(selectedItem.price).toFixed(0)}
              </Typography.Text>
            </p>
            <p className="price">
              {t("point")} : {selectedItem.points}
            </p>
            <p className="price">
              {/* {JSON.parse(selectedItem.description)[i18n.language || "ku"]} */}
              <span>
                {JSON.parse(selectedItem.description)[i18n.language || "ku"]}
              </span>
            </p>
            <Button
              className="modal_card"
              type="link"
              style={{
                fontSize: 17,
              }}
              onClick={() => handleClick(selectedItem)}
            >
              {t("Add_to_cart")}
              <img src={Shopping} alt=" " width={32} height={32} />
            </Button>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default PageSlider;

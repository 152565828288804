/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../styles/Category.css"; // Ensure this import is included
import { Card } from "antd"; // Ant Design imports
import { Link } from "react-router-dom";

const CategoryVendor = () => {
  const [category, setCategory] = useState([]);
  const { t, i18n } = useTranslation();

  const getApiProducts = async () => {
    try {
      const res = await fetch(`https://bardawamplus.com/api/stores`);
      if (!res.ok) {
        throw new Error(`HTTP error! Status: ${res.status}`);
      }
      const data = await res.json();
      setCategory(
        data.data.map((item) => {
          const nameTranslations = JSON.parse(item.name);
          const currentLanguage = i18n.language || "ku";
          const translatedName = nameTranslations[currentLanguage];
          const imageUrl = item.logo
            ? `https://api.bardawamplus.com/storage/${item.logo}`
            : null;
          return {
            key: item.id,
            id: item.id,
            name: translatedName,
            image_url: imageUrl,
          };
        })
      );
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getApiProducts();
  }, [i18n.language]);

  // Slider settings with autoplay and custom arrows
  const settings = {
    dots: false, // Remove dots (pagination)
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true, // Enable autoplay
    autoplaySpeed: 3000, // Speed for autoplay (3 seconds)
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="slide-container">
      <div className="heading">
        <h4>{t("Vendor")}</h4>
        <Link to="/LinsVendor" className="right-aligned-link">
          <h4>{t("Vendor1")}</h4>
        </Link>
      </div>

      <Slider {...settings}>
        {category.map((pp) => (
          <div key={pp.id}>
            {/* Using Ant Design Card */}
            <Card
              className="Card-slider"
              //   hoverable
              cover={
                <Link to={`/Category/${pp.id}`}>
                  <div
                    style={{
                      display: "flex", // Enable flexbox
                      justifyContent: "center", // Horizontally center the content
                      alignItems: "center", // Vertically center the content
                      height: "100%", // Ensure the container takes up full height if needed
                    }}
                  >
                    <img
                      className="card-img"
                      src={pp.image_url}
                      alt={pp.name}
                      style={{
                        height: "120px", // Set height directly
                        width: "120px", // Set width directly
                        objectFit: "contain", // Ensure the image is contained within the set dimensions
                      }}
                    />
                  </div>
                </Link>
              }
            >
              <Card.Meta
                title={
                  <span className="Card-slider-meta-title">{pp.name}</span>
                }
                className="text-center"
              />
            </Card>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default CategoryVendor;

/* eslint-disable react/jsx-pascal-case */
/* eslint-disable jsx-a11y/alt-text */
import { useState, useEffect } from "react";
import {
  Input,
  List,
  Typography,
  Card,
  Image,
  Button,
  Modal,
  // Badge,
} from "antd";
import Slider_Vendor from "../Category_vendor/Slider_vendor";
import "../styles/pages.css";
import PageSlider from "../Slider/Slider";
import { useTranslation } from "react-i18next";
import Shopping from "./image/shopping-cart.png";
import info from "./image/about.png";
import { ArrowUpOutlined } from "@ant-design/icons";
import { animateScroll as scroll } from "react-scroll";

// import AllCategory from "../Category_vendor/AllCategory";
const HomeShop = ({ handleClick }) => {
  const { t, i18n } = useTranslation();
  const [searchText, setSearchText] = useState("");
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);

  useEffect(() => {
    setLoading(true);
    const apiUrl = searchText
      ? `https://bardawamplus.com/api/search?q=${searchText}`
      : "https://bardawamplus.com/api/products";

    fetch(apiUrl)
      .then((res) => {
        if (!res.ok) {
          throw new Error(`HTTP error! Status: ${res.status}`);
        }
        return res.json();
      })
      .then((response) => {
        setLoading(false);
        setDataSource(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  const showItemDetails = (item) => {
    setSelectedItem(item);
    setModalVisible(true);
  };

  const scrollToTop = () => {
    scroll.scrollToTop(); // Scroll to the top of the page
  };

  const [showScrollButton, setShowScrollButton] = useState(false);
  const handleScroll = () => {
    if (window.scrollY > window.innerHeight) {
      setShowScrollButton(true);
    } else {
      setShowScrollButton(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div>
      <div className="body-Home">
        <PageSlider handleClick={handleClick} />
        <Slider_Vendor handleClick={handleClick} />
        <h1 className="heading">{t("Products")}</h1>
        <Input.Search
          className="Search"
          placeholder={t("sent")}
          onSearch={(value) => {
            setSearchText(value);
          }}
          onChange={(e) => setSearchText(e.target.value)}
        />

        {/* <AllCategory /> */}
        <List
          loading={loading}
          grid={{
            // gutter: 1,
            xs: 2,
            sm: 2,
            md: 2,
            lg: 3,
            xl: 3,
          }}
          dataSource={dataSource}
          renderItem={(item) => {
            const nameTranslations = JSON.parse(item.name);
            const currentLanguage = i18n.language || "ku";
            const translatedName = nameTranslations[currentLanguage];
            const nameStore = JSON.parse(item.store.name);
            const StoreName = i18n.language || "ku";
            const Stores = nameStore[StoreName];

            return (
              // <Badge.Ribbon
              //   text={t("buy")}
              //   color="orange"
              //   style={{
              //     margin: 9,
              //   }}
              // >
              <Card
                className="itemCard"
                honorable="true" // Pass string value instead of boolean
                title={<span className="ant-card-title">{translatedName}</span>}
                key={item.id}
                cover={
                  item.image_url ? (
                    <Image
                      src={`https://bardawamplus.com/api/storage/${item.image_url}`}
                      className="Image_Card"
                      alt={t("Product Image")}
                    />
                  ) : null
                }
                actions={[
                  <Button
                    type="link"
                    style={{
                      fontSize: "15px",
                    }}
                    onClick={() => handleClick(item)}
                  >
                    <div>
                      {/* {t("Add_to_cart")} */}
                      <img
                        src={Shopping}
                        alt={t("Shopping Cart")}
                        width={29}
                        height={29}
                      />
                    </div>
                  </Button>,

                  <Button
                    type="link"
                    style={{
                      fontSize: "17px",
                    }}
                    onClick={() => showItemDetails(item)}
                  >
                    <div>
                      {/* {t("info")} */}
                      <img
                        src={info}
                        alt={t("Information")}
                        width={27}
                        height={27}
                      />
                    </div>
                  </Button>,
                ]}
              >
                <Card.Meta
                  title={
                    <Typography.Paragraph className="product">
                      {t("product")}: ${item.discounted_price}
                      <Typography.Text
                        delete
                        type="danger"
                        className="discount"
                      >
                        <span> </span>
                        {parseFloat(item.price).toFixed(0)}
                      </Typography.Text>
                      <Typography.Paragraph className="point">
                        {t("point")}: {item.points}
                        <br />
                        {t("nameStore")}:
                        <br />
                        {Stores}
                      </Typography.Paragraph>
                    </Typography.Paragraph>
                  }
                />
              </Card>
              // </Badge.Ribbon>
            );
          }}
        />

        {showScrollButton && (
          <Button
            type="primary"
            className="scroll-to-top-button"
            onClick={scrollToTop}
          >
            <ArrowUpOutlined />
          </Button>
        )}

        <Modal
          className="modal"
          style={{ direction: "rtl" }}
          title={
            selectedItem?.name &&
            JSON.parse(selectedItem?.name)[i18n.language || "ku"]
          }
          open={modalVisible}
          onCancel={() => setModalVisible(false)}
          footer={[
            <Button key="close" onClick={() => setModalVisible(false)}>
              {t("Close")}
            </Button>,
          ]}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <p>
              {selectedItem?.description &&
                JSON.parse(selectedItem?.description)[i18n.language || "ku"]}
            </p>
            {selectedItem?.image_url && (
              <img
                style={{ width: "40%" }}
                src={`https://bardawamplus.com/api/storage/${selectedItem?.image_url}`}
                alt={t("Selected Product")}
              />
            )}
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default HomeShop;

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Input, List, Typography, Card, Image, Button, Modal } from "antd";
import { useTranslation } from "react-i18next";
import "../styles/Category.css"; // Import your CSS
import Shopping from "../Navbarshoping/image/shopping-cart.png";
import info from "../Navbarshoping/image/about.png";
import { ArrowUpOutlined } from "@ant-design/icons";
import { animateScroll as scroll } from "react-scroll";

const Category = ({ handleClick }) => {
  const { id } = useParams(); // Get the store/category ID from the URL
  const { t, i18n } = useTranslation();
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [showScrollButton, setShowScrollButton] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setShowScrollButton(window.scrollY > window.innerHeight);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    scroll.scrollToTop(); // Scroll to the top of the page
  };

  useEffect(() => {
    const getApiProducts = async () => {
      try {
        setLoading(true);
        const productsRes = await fetch(
          `https://bardawamplus.com/api/stores/${id}/products`
        );

        if (!productsRes.ok) {
          throw new Error(`HTTP error! Status: ${productsRes.status}`);
        }

        const productsData = await productsRes.json();
        setProducts(
          productsData.data.map((item) => ({
            id: item.id,
            name: JSON.parse(item.name) || {},
            price: item.price,
            image_url: item.image_url
              ? `https://bardawamplus.com/api/storage/${item.image_url}`
              : null,
            discounted_price: item.discounted_price,
            description: JSON.parse(item.description) || {},
            points: item.points,
            pinned: item.pinned,
          }))
        );
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    getApiProducts();
  }, [id]);

  const showItemDetails = (item) => {
    setSelectedItem(item);
    setModalVisible(true);
  };

  const renderItem = (item) => {
    const translatedName = item.name[i18n.language] || "N/A";

    return (
      <Card
        className="itemCard"
        title={<span className="ant-card-title">{translatedName}</span>}
        key={item.id}
        cover={
          item.image_url ? (
            <Image
              src={item.image_url}
              className="Image_Card"
              alt={t("Product Image")}
            />
          ) : (
            <div>No image available</div>
          )
        }
        actions={[
          <Button
            type="link"
            style={{ fontSize: "15px" }}
            onClick={() => handleClick(item)}
          >
            <img
              src={Shopping}
              alt={t("Shopping Cart")}
              width={29}
              height={29}
            />
          </Button>,
          <Button
            type="link"
            style={{ fontSize: "17px" }}
            onClick={() => showItemDetails(item)}
          >
            <img src={info} alt={t("Information")} width={27} height={27} />
          </Button>,
        ]}
      >
        <Card.Meta
          title={
            <Typography.Paragraph className="product">
              {t("product")}: ${item.discounted_price}
              <Typography.Text delete type="danger" className="discount">
                <span> </span>
                {parseFloat(item.price).toFixed(0)}
              </Typography.Text>
              <Typography.Paragraph className="point">
                {t("point")}: {item.points}
              </Typography.Paragraph>
            </Typography.Paragraph>
          }
        />
      </Card>
    );
  };

  if (loading) {
    return <div>Loading products...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div>
      <div className="body-Home">
        <h1 className="heading">{t("Products")}</h1>
        <Input.Search
          className="Search"
          placeholder={t("sent")}
          onSearch={setSearchText}
          onChange={(e) => setSearchText(e.target.value)}
        />
        <List
          grid={{
            // gutter: 16,
            xs: 2,
            sm: 2,
            md: 2,
            lg: 3,
            xl: 3,
          }}
          dataSource={products.filter((product) =>
            product.name[i18n.language]
              ?.toLowerCase()
              .includes(searchText.toLowerCase())
          )}
          loading={loading}
          renderItem={renderItem}
        />

        {showScrollButton && (
          <Button
            type="primary"
            className="scroll-to-top-button"
            onClick={scrollToTop}
          >
            <ArrowUpOutlined />
          </Button>
        )}

        <Modal
          className="modal"
          style={{ direction: "rtl" }}
          title={selectedItem?.name[i18n.language]}
          open={modalVisible}
          onCancel={() => setModalVisible(false)}
          footer={[
            <Button key="close" onClick={() => setModalVisible(false)}>
              {t("Close")}
            </Button>,
          ]}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <p>{selectedItem?.description[i18n.language]}</p>
            {selectedItem?.image_url && (
              <img
                style={{ width: "40%" }}
                src={selectedItem.image_url}
                alt={t("Selected Product")}
              />
            )}
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default Category;

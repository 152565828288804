/* eslint-disable jsx-a11y/img-redundant-alt */
import "../styles/Aboutus.css";
import Photo1 from "../Images/AboutUs/111.png";
import Photo2 from "../Images/AboutUs/222.jpg";
import Photo3 from "../Images/AboutUs/333.png";
import Photo4 from "../Images/AboutUs/444.png";
import Photo41 from "../Images/AboutUs/555.jpg";
import Photo42 from "../Images/AboutUs/666.png";
import Photo43 from "../Images/AboutUs/777.jpg";
import Photo44 from "../Images/AboutUs/888.png";
import Photo45 from "../Images/AboutUs/999.png";
import photo5 from "../Images/PHOTO5.jpg";
import photo6 from "../Images/PHOTO6.jpg";
import photo7 from "../Images/new .jpg";

import { ArrowUpOutlined } from "@ant-design/icons";
import { animateScroll as scroll } from "react-scroll"; // Import the scroll function
import { useTranslation } from "react-i18next";
import { Button } from "antd";
import { useEffect, useState } from "react";
const AboutUs = () => {
  const { t } = useTranslation();
  const scrollToTop = () => {
    scroll.scrollToTop(); // Scroll to the top of the page
  };
  const [showScrollButton, setShowScrollButton] = useState(false);
  const handleScroll = () => {
    if (window.scrollY > window.innerHeight) {
      setShowScrollButton(true);
    } else {
      setShowScrollButton(false);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div className="ALL">
      <div className="Text-Top">
        <h1 className="Text-About">{t("AboutA")}</h1>
        <div>
          <img src={Photo1} alt="image" className="image" />
        </div>
        <h1 className="Text-About">{t("AboutB")}</h1>
        <div>
          <img src={Photo2} alt="image" className="image" />
        </div>
        <h1 className="Text-About">{t("AboutC")}</h1>
        <div>
          <img src={Photo3} alt="image" className="image" />
        </div>
        <h1 className="Text-About">{t("AboutD")}</h1>
        <div>
          <img src={Photo4} alt="image" className="image" />
        </div>
        <h1 className="Text-About">{t("AboutE")}</h1>
        <h1 className="Text-About">{t("AboutQ")}</h1>
        <h1 className="Text-About">{t("AboutF")}</h1>
        <div>
          <img src={Photo41} alt="image" className="image" />
        </div>

        <h1 className="Text-About">{t("AboutJ")}</h1>
        <div>
          <img src={Photo42} alt="image" className="image" />
        </div>

        <h1 className="Text-About">{t("AboutH")}</h1>
        <h1 className="Text-About">{t("AboutI")}</h1>

        <div>
          <img src={Photo43} alt="image" className="image" />
        </div>
        <h1 className="Text-About">{t("AboutR")}</h1>

        <h1 className="Text-About">{t("AboutK")}</h1>
        <div>
          <img src={Photo44} alt="image" className="image" />
        </div>
        <h1 className="Text-About">{t("AboutM")}</h1>
        <h1 className="Text-About">{t("AboutN")}</h1>

        <h1 className="Text-About">{t("AboutO")}</h1>
        <div>
          <img src={Photo45} alt="image" className="image" />
        </div>

        <h1 className="Text-About">{t("AboutP")}</h1>

        <div>
          <img src={photo5} alt="image" className="image" />
        </div>
        <div>
          <img src={photo6} alt="image" className="image" />
        </div>
        <div>
          <img src={photo7} alt="image" className="image" />
        </div>
        <h1 className="Text-About1">{t("Aboutn1")}</h1>
        <h1 className="Text-About1">{t("Aboutn2")}</h1>
        <h1 className="Text-About1">{t("Aboutn3")}</h1>
        <h1 className="Text-About1">{t("Aboutn4")}</h1>
        <h1 className="Text-About1">{t("Aboutn5")}</h1>
        <h1 className="Text-About1">{t("Aboutn6")}</h1>
        <h1 className="Text-About1">{t("Aboutn7")}</h1>
        <h1 className="Text-About1">{t("Aboutn8")}</h1>
      </div>
      {showScrollButton && (
        <Button
          type="primary"
          className="scroll-to-top-button"
          onClick={scrollToTop} // Add onClick handler for scrolling to top
        >
          <ArrowUpOutlined />
        </Button>
      )}
    </div>
  );
};

export default AboutUs;

import React, { useEffect, useState } from "react";
import {
  AppBar,
  Menu,
  MenuItem,
  Tabs,
  Tab,
  Toolbar,
  Button,
  useMediaQuery,
  useTheme,
  ListItemIcon,
  IconButton,
} from "@mui/material";
import { Box } from "@mui/system";
import { Link, Outlet } from "react-router-dom"; // Ensure this is in your imports
import "../styles/UpdateNavbar.css";
import logo from "./image/LOGO.png";
import iraq from "./image/iraq.png";
import Kurdistan from "./image/kurdstan.png";
import AccountCircleIcon from "@mui/icons-material/AccountCircle"; // Profile icon
import InfoIcon from "@mui/icons-material/Info"; // Information icon
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard"; // Gift icon
import HomeIcon from "@mui/icons-material/Home";
import LanguageIcon from "@mui/icons-material/Language";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import DrawerComp from "./Drawer";
import i18n from "../../i18n";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import { ShoppingCartOutlined } from "@ant-design/icons"; // Import shopping cart icon

const Navbar = ({ size, setShow }) => {
  // Accept size and setShow as props

  // eslint-disable-next-line no-unused-vars
  const [cart, setCart] = useState([]);
  // Effect to manage cart length

  const [value, setValue] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [darkMode, setDarkMode] = useState(
    localStorage.getItem("selectedTheme") === "dark"
  );
  const [login, setLogin] = useState(true);
  const seelogin = async () => {
    const loginCookieValue = await Cookies.get("login");
    setLogin(loginCookieValue === "true");
  };

  useEffect(() => {
    seelogin();
  }, []);

  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down("md"));
  // eslint-disable-next-line no-unused-vars
  const [language, setLanguage] = useState("");
  const { t } = useTranslation();

  const onChangeLang = (selectedLang) => {
    setLanguage(selectedLang);
    i18n.changeLanguage(selectedLang);
    Cookies.set("language", selectedLang);
  };

  useEffect(() => {
    const savedLang = Cookies.get("language");
    if (savedLang) {
      setLanguage(savedLang);
      i18n.changeLanguage(savedLang);
    }
  }, []);

  useEffect(() => {
    document
      .querySelector("body")
      .setAttribute("data-theme", darkMode ? "dark" : "light");
    localStorage.setItem("selectedTheme", darkMode ? "dark" : "light");
  }, [darkMode]);

  const handleLanguageClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLanguageClose = () => {
    setAnchorEl(null);
  };

  const TabLabelWithIcon = ({ label }) => (
    <div style={{ display: "flex", alignItems: "center" }}>
      {label} <ArrowDropDownIcon fontSize="large" />
    </div>
  );

  return (
    <React.Fragment>
      <AppBar
        sx={{
          backgroundColor: "aliceblue",
          color: "black",
          fontWeight: "bold",
          fontSize: "16px",
        }}
      >
        <Toolbar>
          <div className="class-logo">
            <img src={logo} alt="Logo" height={60} width={60} />
          </div>
          {isMatch ? (
            <DrawerComp size={cart.length} setShow={setShow} />
          ) : (
            <>
              <Tabs
                sx={{ marginLeft: "auto" }}
                indicatorColor="secondary"
                textColor="inherit"
                value={value}
                onChange={(e, value) => setValue(value)}
              >
                <Tab
                  component={Link}
                  to="/"
                  label={t("home")}
                  icon={<HomeIcon fontSize="large" />}
                  iconPosition="start"
                  sx={{ fontWeight: "bold", fontSize: "16px" }}
                />

                {login && (
                  <Tab
                    component={Link}
                    to="/Profile"
                    label={t("Profile")}
                    iconPosition="start"
                    icon={<AccountCircleIcon fontSize="large" />}
                    sx={{ fontWeight: "bold", fontSize: "16px" }}
                  />
                )}
                <Tab
                  component={Link}
                  to="/Gift"
                  label={t("gife")}
                  icon={<CardGiftcardIcon fontSize="large" />}
                  iconPosition="start"
                  sx={{ fontWeight: "bold", fontSize: "16px" }}
                />
                <Tab
                  component={Link}
                  to="/aboutUs"
                  label={t("About")}
                  icon={<InfoIcon fontSize="large" />}
                  iconPosition="start"
                  sx={{ fontWeight: "bold", fontSize: "16px" }}
                />
                <Tab
                  label={<TabLabelWithIcon label={t("lang")} />}
                  icon={<LanguageIcon fontSize="large" />}
                  iconPosition="start"
                  onClick={handleLanguageClick}
                  sx={{ fontWeight: "bold", fontSize: "16px" }}
                />
              </Tabs>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleLanguageClose}
              >
                <MenuItem
                  onClick={() => {
                    onChangeLang("ku");
                    handleLanguageClose();
                  }}
                >
                  <ListItemIcon>
                    <img
                      src={Kurdistan}
                      alt="Kurdistan Flag"
                      height={25}
                      width={25}
                    />
                  </ListItemIcon>
                  {t("Kurdish")}
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    onChangeLang("ar");
                    handleLanguageClose();
                  }}
                >
                  <ListItemIcon>
                    <img src={iraq} alt="Iraq Flag" height={30} width={30} />
                  </ListItemIcon>
                  {t("Arabic")}
                </MenuItem>
              </Menu>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginLeft: "auto",
                }}
              >
                <div
                  style={{ display: "flex", alignItems: "center", gap: "16px" }}
                >
                  {" "}
                  {/* Adjust gap as needed */}
                  <div
                    className="cart"
                    onClick={() => setShow(false)}
                    data-count={size} // Using data-count attribute for displaying item count
                  >
                    <span>
                      <Link className="cart" to="/Cart">
                        <ShoppingCartOutlined
                          style={{ fontSize: 33 }} // No color here; it's handled in CSS
                        />
                      </Link>
                    </span>
                  </div>
                  <IconButton
                    onClick={() => setDarkMode((prev) => !prev)}
                    sx={{
                      color: darkMode ? "#ffb74d" : "#424242",
                      width: "40px",
                      height: "40px",
                    }}
                  >
                    <span style={{ fontSize: "24px" }}>
                      {darkMode ? "🌙" : "☀️"}
                    </span>
                  </IconButton>
                </div>

                {login ? null : (
                  <Button
                    variant="contained"
                    className="sign-in-button"
                    component={Link}
                    to="/login"
                    sx={{ marginLeft: 2, fontSize: "16px" }}
                  >
                    {t("Sign in")}
                  </Button>
                )}
              </Box>
            </>
          )}
        </Toolbar>
      </AppBar>
      <Outlet />
    </React.Fragment>
  );
};

export default Navbar;
